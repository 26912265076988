.data-box {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.data-icon {
    margin-right: 0.5rem;
}

.data-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.data-value {
    font-size: 1rem;
    font-family: 'Figtree Regular', sans-serif;
    margin-right: 0.25rem;
}

.data-label {
    font-family: 'Figtree Regular', sans-serif;
    font-size: 0.75rem;
    color: lightgrey;
    margin-top: 0.0625rem;
}
