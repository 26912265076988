body {
  /* background-image: url('./../public/background-dark.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;  */
  height: 100vh; 
  width: 100%;
  margin: 0;
  overflow: hidden;
  overflow-x: hidden;
  background-color: #1E1E20;
}

.app-container {
  font-family: 'Your Preferred Font', sans-serif;
  height: 100vh;
  overflow: hidden;
}
