.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #27282B;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 16px 16px 0px 0px;
    border: 1px solid #404346;
}

.nav-item {
    display: flex;
    font-family: 'Figtree SemiBold', sans-serif;
    flex-direction: column;
    align-items: center;
    color: #F8F7F2;
    font-size: 11px;
    letter-spacing: 0.01rem;
}

.nav-icon {
    font-size: 20px;
    margin-bottom: 5px;
}