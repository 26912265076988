.switch {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 50%;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 1px;
    top: 1px; 
    background-color: #FAFAFA;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #0AE194;
}

input:checked + .slider:before {
    background-color: #0AE194;
}

