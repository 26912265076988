* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

html, body {
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* Fonts */
@font-face {
  font-family: 'Figtree Regular';
  src: url('./../public/fonts/Figtree-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree SemiBold';
  src: url('./../public/fonts/Figtree-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Figtree ExtraBold';
  src: url('./../public/fonts/Figtree-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
