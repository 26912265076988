.air-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.air-real-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
    gap: 1rem;
}

.air-chart {
    width: 65%;
}

.chart-container canvas {
    width: 100% !important;
    height: 100% !important;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}