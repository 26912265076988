.segmented-control {
    display: flex;
    overflow: hidden;
}

.segment {
    flex: 1;
    padding: 10px 20px;
    color: #F8F7F2;
    background-color: #1E1E20;
    border-bottom: 1px solid #404346;
    border-top: none;
    border-right: none;
    border-left: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.segment:hover {
    color: #0AE194;
}

.segment.active {
    background-color: #1E1E20;
    color: #0AE194;
    border-bottom: 1px solid #0AE194;
}

.non-selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.segment:disabled {
    background-color: #1E1E20;
    cursor: not-allowed;
    color: #F8F7F2;
}

.segment.active:disabled {
    background-color: #1E1E20;
    color: #F8F7F2;
}

.nft-container {
    width: 100%;
    background: #1E1E20;
    overflow: hidden;
    color: #FAFAFA;
    margin-bottom: -2rem;
}

.nft-info {
    display: flex;
    padding: 20px;
    padding-bottom: 10px;
    align-items: center;
}

.nft-info h3 {
    font-family: 'Figtree SemiBold', sans-serif;
    font-size: 18px;
    line-height: 95%;
}

.nft-info p {
    font-family: 'Figtree Regular', sans-serif;
    font-size: 14px;
}

.nft-image {
    width: 91px;
    height: 91px;
    margin-right: 15px;
}

.nft-details {
    display: flex;
    flex-direction: column;
}