.add-device-box {
    background-color: #1E1E20;
    color: #FAFAFA;
    border-radius: 1rem;
    border: 0.0625rem solid #404346;
    padding: 0.9375rem;
    flex-shrink: 0;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(30, 30, 32, 0.15);
    margin-bottom: 1rem;
}

.add-text {
    color: #FAFAFA;
    font-size: 1rem;
    margin-top: 2rem;
    font-family: 'Figtree SemiBold', sans-serif;
}
